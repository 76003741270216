.app {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.time {
  font-size: 3rem;
  padding: 2rem;
}

.button {
  padding: .6rem 1.5rem;
  margin: .4rem;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: .8rem;
  border-style: groove;
}

.button:focus {
  outline-width: 0;
}

.button-primary:hover {
  background-color: #2641d4;
  border: 1px solid #1b1f2b;
}

.button-primary-active {
  background-color: #3151ff;
  border: 1px solid #152684;
  color: white;
}

.button-primary-inactive {
  background-color: #3151ff;
  border: 1px solid #152684;
  color: white;
}

.timer-container {
  width: 150px;
  display: flex;
  align-items: center;
  justify-items: center;
  font-weight: 600;
  font-size: 20px;
  padding: .6rem 1.5rem;
  text-align: center;
}

.timer-lebel {
  display: flex;
  font-size: 2rem;
}