.cell-container {
  outline: 1px solid black;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #ecf0f5;
  box-shadow: inset 1px 0px 7px 3px #cec5c5;
  margin: 2px;
}

.hide {
  display: none;
}

.cell-container.cell-open {
  background-color: white;
  box-shadow: none;
  color: #100c44;
}

.cell-container:hover {
  background-color: #adbcc5;
}